import { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeExpressCheckoutElementOptions } from '@stripe/stripe-js'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
// import { analytics } from 'index'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import styles from './Payment.module.scss'

export function CheckoutForm({ clientSecret, planId }: any) {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [pollId, setPollId] = useState<any>(null)
  const [payment, setPayment] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)
  const [stripePaymentStatus, setStripePaymentStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [paymentStatus, setPaymentStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [paymentData, setPaymentData] = useState<any>(null)

  async function getPayment(planId: any) {
    try {
      const response: any = await axios
        .get(`${AppConstants.API_URL}/payment-plan/${planId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            setPaymentData(res?.data?.data)
            setPaymentStatus(StatusEnum.Success)
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (stripePaymentStatus === StatusEnum.Success && !pollId) {
      let intId = setInterval(() => {
        if (planId) {
          getPayment(planId)
        }
        if (paymentData?.status === 'completed' && pollId) {
          clearInterval(pollId)
        }
      }, 1000)
      setPollId(intId)
    }

    return () => {
      if (pollId) {
        clearInterval(pollId)
      }
    }
  }, [stripePaymentStatus, pollId])

  useEffect(() => {
    if (paymentStatus === StatusEnum.Success && paymentData?.status === 'completed') {
      clearInterval(pollId)
      setPayment('success')
    }
  }, [paymentStatus])

  const handlePayment = async (e: any) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }

    if (!buttonDisable) {
      setButtonDisable(true)
      let stripeValidation = await elements.submit()

      if (stripeValidation.error) {
        console.log(stripeValidation.error)
        setButtonDisable(false)
        return
      } else {
        setPayment('in_progress')
      }

      stripe
        .confirmPayment({
          elements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: '',
          },
        })
        .then((response) => {
          if (response.paymentIntent?.status === 'succeeded') {
            setStripePaymentStatus(StatusEnum.Success)
          } else {
            setStripePaymentStatus(StatusEnum.Failed)
            setPayment('')
            alert('Payment failed. Please try again')
          }
        })
        .catch((error) => {})
    }
  }
  const expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
    buttonTheme: {
      applePay: 'white',
      googlePay: 'white',
    },
    buttonType: {
      applePay: 'buy',
      googlePay: 'buy',
    },
    wallets: {
      googlePay: 'always',
      applePay: 'always',
    },
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'payment',
      page_location: '/payment',
    })
  }, [])

  return (
    <>
      <form
        onSubmit={handlePayment}
        style={{ flex: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}
      >
        <div className={styles.paymentForm}>
          <PaymentElement
            options={{
              layout: 'accordion',
              paymentMethodOrder: ['card', 'applePay'],
              wallets: {
                applePay: 'auto',
                googlePay: 'auto',
              },
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            type="submit"
            sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}
            style={
              payment === 'success'
                ? {
                    backgroundImage: "url('/images/doneImg.svg')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }
                : {}
            }
          >
            <span>{payment !== 'success' && payment !== 'in_progress' && 'Pay'}</span>
            {payment === 'in_progress' && (
              <CircularProgress
                size={20}
                className={styles.progressBar}
                sx={{ color: 'white', width: '14px', height: '14px', left: '90%' }}
              />
            )}
          </Button>
        </div>
      </form>
    </>
  )
}
